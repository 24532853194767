import { SubformProps } from "."

export const Submitted = ({ formData }: Partial<SubformProps>) => {
  let payment = formData.submissionResponse?.success?.MonthlyPayment

  if (parseFloat(formData.incentive?.estPriceWithIncentives) > 0) {
    payment = formData.incentive.estPriceWithIncentives
  }

  // TODO: handle case when documents are not automatically sent

  return <div className="alert alert-success">
    <div className="mb-3"><b>Congratulations!</b></div>
    {formData.loanDocsIssuedDate ? <div className="mb-3">
      Your application has been submitted and approved.
      You should be receiving a package from Docusign that must be signed
      within 48 hours to complete the process.
    </div> : <div>
      Your application has been pre-qualified pending document verification. A member of
      our banking team will reach out within 48 business hours.
    </div>}
    {!formData.incentive && payment ? <div className="mb-3">
      Expected Monthly Payment: <b>${payment}</b>
    </div> : null}
  </div>
}


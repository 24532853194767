import React, { useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  createBrowserRouter,
  createRoutesFromElements,
  redirect,
  useLocation,
} from 'react-router-dom'
import { Spinner, SpinnerSize } from '@fluentui/react'
import * as Pages from './pages'
import { useAppState, useLocalStorage } from './hooks'
import { AuthError, ErrorBoundary, MiniSection, InstallerTermsModal, Sidebar, TopNav } from './components'
import { StorageKey } from './lib'
import { FaTriangleExclamation } from 'react-icons/fa6'
import useScreenSize from './hooks/use-screen-size'
import InstallerSearch from './components/installer-search'

const Advisory = ({ advisory }) => {
  if (!advisory) return null

  const { level, message } = advisory

  return <MiniSection type={level}>
    <div className="fs-4 mb-2 fw-semibold"><FaTriangleExclamation size={20} /> System Advisory</div>
    {message}
  </MiniSection>
}

const RouterWrap = () => {
  const {
    registration: { installer, loading, authError },
    tenant
  } = useAppState()
  const [impersonate, , clearImpersonate] = useLocalStorage(StorageKey.ImpersonateUserId, undefined)
  const { width } = useScreenSize()
  const [sidebarOpen, setSidebarOpen] = useState(width > 768)

  const location = useLocation()
  const { pathname, search } = location

  if (loading || tenant.loading) {
    return <div style={{ padding: '10rem' }}>
      <Spinner size={SpinnerSize.large} />
    </div>
  }

  return <div className={[
    "installer-page",
    sidebarOpen ? 'sb-open' : '',
    installer ? 'logged-in' : ''
  ].join(' ')}>
    {installer ?
      <Sidebar
        onToggle={() => setSidebarOpen(!sidebarOpen)}
        isOpen={sidebarOpen}
      />
      : null}
    <div className="page-content">
      <TopNav />
      {installer && installer.isSupport ?
        <div className="d-md-none d-lg-block d-lg-none d-xl-block d-xl-none ps-3 pe-3 pb-3">
          <InstallerSearch />
        </div>
        : null}
      {(installer && impersonate) ? <div>
        <div className="impersonate-warning">
          <MiniSection type='danger'>
            {/* 
            Note that emails generated on behalf of this user will appear to come from 
            the impersonating user, which is possibly a security risk if the banker or 
            super admin is acting in good faith.... although we can't actually
            send from the user, so maybe it's a non-issue?
          */}
            <p>
              You are impersonating <b>{installer.email}</b>
              ({installer.access}) with <b>{installer.installerID}</b>
            </p>
            <div className="buttons">
              <button type="button" className="btn btn-danger"
                onClick={() => {
                  clearImpersonate()
                  window.location.reload()
                }}
              >Exit</button>
            </div>
          </MiniSection>
        </div>
        {/* push content down the page to acommodate fixed position warning */}
        <div style={{ height: '8em' }}></div>
      </div> : null}
      {authError ? <AuthError authError={authError} /> : null}

      <Advisory advisory={tenant?.config?.advisory} />
      <Routes>
        <Route path="/apply/:id" element={<Pages.ConsumerApplicationPage />} />
        <Route path="/apply" element={<Pages.ConsumerApplicationPage />} />
        <Route path="/login" element={<Pages.LoginPage />} />
        <Route path="/verify-email" element={<Pages.VerifyEmailPage />} loader={() => {
          if (localStorage.getItem(StorageKey.InstallerToken)) {
            throw redirect('/installer-pos')
          }

          return null
        }} />
        <Route path="/fund-release-request/:id" element={<Pages.FundingReleasePage />} />
        {/* legacy login workflow */}
        <Route path="/verify-login-token" element={<Pages.VerifyEmailPage />} />
        {!installer ? (<>
          <Route path="/profile" element={<Pages.InstallerProfilePage />} />
          <Route path="*" element={<Navigate to={`/login?route=${encodeURIComponent(pathname + (search ? `?${search}` : ''))}`} />} />
        </>) : <>
          {installer.isConsumer ? (
            <>
              <Route path="/applications" element={<Pages.ApplicationsPage />} />
              <Route path="/profile" element={<Pages.InstallerProfilePage />} />
              <Route path="*" element={<Navigate to="/applications" />} />
            </>
          ) : (
            <>
              <Route path="/profile" element={<Pages.InstallerProfilePage />} />
              <Route path="/calculator" element={<Pages.CalculatorPage />} />
              {installer.isIntegrator ? <Route path="/installers" element={<Pages.InstallerOrgsPage />} /> : null}
              {installer.isSupport ? <>
                <Route path="/dashboard" element={<Pages.BankerDashboardPage />} />
                <Route path="/prequal-reports" element={<Pages.CreditReportsPage />} />
                <Route path="/installers" element={<Pages.BankerDashboardPage />} />
                <Route path="/tools" element={<Pages.ToolsPage />} />
                <Route path="/search-results" element={<Pages.SearchResultsPage />} />
                <Route path="/installer-status-config" element={<Pages.InstallerStatusConfigPage />} />
              </> : null}

              <Route path="/projects/:installerID" element={<Pages.InstallerProjectsPage />} />
              <Route path="/project/:id" element={<Pages.ProjectPage />} />
              <Route path="/projects" element={<Pages.InstallerProjectsPage />} />
              <Route path="/training" element={<Pages.TrainingPage />} />
              <Route path="/installer-admin/:installerID" element={<Pages.InstallerAdminPage />} />
              <Route path="/installer-admin" element={<Pages.InstallerAdminPage />} />


              {/* legacy route(s) */}
              <Route path="/installer-pos" element={<Pages.CalculatorPage />} />
              <Route path="/doc-upload" element={<Pages.DocUploadPage />} />

              <Route path="*" element={<Navigate to="/installer-pos" />} />
            </>
          )}
        </>}
      </Routes>
      {installer?.needsTermsAcceptance ? <InstallerTermsModal
        terms={tenant?.config?.currentInstallerTerms}
      /> : null}
      <div className="oe-footer">
        <div className="powered-by">
          <img src="/images/pb-oneethos-black.png" height="50" alt="oneethos-logo" />
          <div className="build-id mt-1">v-{process.env.REACT_APP_BUILD_ID || 'dev'}</div>
        </div>
      </div>
    </div>
  </div>
}

// not a huge fan of this approach, but otherwise struggled to redirect
// to installer-pos if token already exists; otherwise verify-email page
// is buggy
const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="*" element={
      <ErrorBoundary>
        <RouterWrap />
      </ErrorBoundary>
    } />
  )
)

export default router
import { useAppState } from "../../hooks"

export const UnableToProceed = () => {
  const { tenant } = useAppState()
  return <div>
    <h3>Important Update Regarding Your Eligibility</h3>
    <p>
      Thank you for considering {tenant.config.name} for your solar financing needs.
      Unfortunately, we are unable to proceed with your loan application at this time.
    </p>
    <p>
      You will receive an email shortly that includes additional
      information regarding this decision, including:
    </p>
    <ul>
      <li>The specific reasons why your prequalification was not approved.</li>
      <li>The name and contact details of any credit reporting agency used.</li>
      <li>Your rights to obtain a free copy of your credit report and dispute its accuracy.</li>
    </ul>
    <p>
      Please review this information carefully once you receive it. If you have any
      questions, our customer service team will be available to assist you.
    </p>
  </div>
}
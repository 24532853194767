import { dollars, mmddyyyy, Adder } from "."
import { daysBetween } from "./date-utils"
import { AdderTypes, ProjectType } from "./loan-application"

type PaymentScheduleItem = {
  date: Date
  daysInPeriod: number
  interestDue: number
  principalDue: number
  paymentAmount: number
  endingPrincipal: number
}

export type AmortizationSchedule = {
  apr: number
  schedule: PaymentScheduleItem[]
  payment: number
  sumOfTotalPayments: number
  loanDate: Date
  loanAmount: number
  fees: number
  firstPaymentDate: Date
  finalPaymentDate: Date
  financeCharge: number
}

export class LoanCalculations {
  static estimatedClosingCosts(
    amt: number,
    state: string,
    projectType: ProjectType
  ): number {
    const detail = this.estimatedClosingCostsDetail(amt, state, projectType)
    return Object.values(detail).reduce((acc, val) => acc + val, 0)
  }

  static estimatedClosingCostsDetail(
    amt: number,
    state: string,
    projectType: ProjectType
  ): { fees: number, docStamps?: number } {
    if (['commercial', 'non-profit'].includes(projectType)) {
      return { fees: amt * 0.015 }
    } else {
      return { fees: 1125, docStamps: (state === 'FL' ? (((amt + 1125) / 100) * 0.35) : 0) }
    }
  }

  static paymentByTerm(
    rate: number,
    term: number,
    loanAmount: number,
    state: string,
    projectType: ProjectType
  ): string {
    if (!loanAmount) { return '$000.00' }

    // assume loan date is today
    const loanDate = new Date()
    const firstPaymentDate = this.firstPaymentDate(loanDate)
    const { payment } = this.getPaymentAndAmortizationSchedule(
      loanDate,
      term,
      loanAmount,
      this.estimatedClosingCosts(loanAmount, state, projectType),
      rate / 100,
      firstPaymentDate
    )

    return dollars(payment)
  }

  // Function to find the first payment date
  static firstPaymentDate(loanDate: Date): Date {
    const todayDate = new Date(loanDate)
    const newDay = todayDate.getDate()
    const newMonth = todayDate.getMonth() + 1

    // Initialize variables for the adjusted month and day
    let adjustedMonth, adjustedDay

    if (newDay < 19) {
      adjustedMonth = newMonth + 1 // Move to next month
      adjustedDay = 16 // Set day to 16
    } else {
      adjustedMonth = newMonth + 2
      adjustedDay = 5
    }

    // Shift the year if necessary
    let adjustedYear = todayDate.getFullYear()
    if (adjustedMonth > 12) {
      adjustedMonth -= 12
      adjustedYear += 1
    }

    return new Date(adjustedYear, adjustedMonth - 1, adjustedDay) // Return as Date object
  }

  // NOTE: should this be calculated based on the schedule instead???
  static finalPaymentDate(loanDate: Date, loanDurationMonths: number): Date {
    const dt = new Date(loanDate)
    dt.setMonth(dt.getMonth() + loanDurationMonths)
    return dt
  }

  // Function to calculate the fixed monthly payment amount using amortization formula
  static estimatedFixedPayment(
    principal: number,
    annualInterestRate: number,
    totalPeriods: number
  ): number {
    const monthlyInterestRate = annualInterestRate / 12
    const payment = principal * (
      monthlyInterestRate * Math.pow(1 + monthlyInterestRate, totalPeriods)
    ) / (Math.pow(1 + monthlyInterestRate, totalPeriods) - 1)
    return payment
  }

  static calcSystemCost(solarCost: number, adders: Adder[] = []): number {
    let total = solarCost
    adders.forEach(adder => {
      const amount = adder.amount
      if (adder.description !== AdderTypes.Downpayment) {
        total += amount
      }
    })
    return total
  }

  static calcLoanAmount(solarCost: number, adders: Adder[] = []): number {
    let total = solarCost
    adders.forEach(adder => {
      const amount = adder.amount
      if (adder.description === AdderTypes.Downpayment) {
        total -= amount
      } else {
        total += amount
      }
    })
    return total
  }


  static getPaymentAndAmortizationSchedule(
    loanDate: Date,
    term: number,
    loanAmount: number,
    fees: number,
    annualInterestRate: number,
    paymentStartDate?: Date,
    interestOnlyPayments?: number,
  ): AmortizationSchedule {
    // Daily interest rate for 365/365 method
    const rate = annualInterestRate > 1 ? annualInterestRate / 100 : annualInterestRate
    const dailyInterestRate = rate / 365
    const firstPaymentDate = paymentStartDate || this.firstPaymentDate(loanDate)
    const amountFinanced = loanAmount + fees
    const daysInFirstPeriod = daysBetween(loanDate, firstPaymentDate)

    if (!term || !annualInterestRate || !loanAmount || fees === undefined) {
      throw Error(`cannot calculate payment; required parameters: term=${term}, annualInterestRate=${annualInterestRate}, loanAmount=${loanAmount}, fees=${fees}`)
    }

    if (daysInFirstPeriod < 5) {
      console.warn(`daysInFirstPeriod is less than 5: ${daysInFirstPeriod}; may create problems in amortization calculation`)
    }

    if (annualInterestRate > 1) {
      console.warn(`annualInterestRate of ${annualInterestRate} is greater than 1; assuming you meant ${annualInterestRate / 100}`)
    }

    console.log(`calculating payment (date=${mmddyyyy(loanDate)}, firstPaymentDate=${mmddyyyy(firstPaymentDate)}, amountFinanced=${amountFinanced}, annualInterestRate=${annualInterestRate}, term=${term})`)

    // initial guess at the fixed payment amount
    let fixedPaymentAmount = this.estimatedFixedPayment(amountFinanced, annualInterestRate, term)

    // Loop to generate the payment schedule
    let attempts = 0
    let schedule: PaymentScheduleItem[] = []
    let adjustmentRatio = 0.4
    let offByPct
    let outstandingPrincipal = amountFinanced
    let offBy = 1_000_000

    // tolerance for gap between payment amount and remaining principal in dollars
    const tolerance = 0.0001

    do {
      schedule = []
      attempts++
      outstandingPrincipal = amountFinanced
      let currentDate = new Date(loanDate)
      let nextDate = new Date(firstPaymentDate)

      for (let i = 0; i < term; i++) {
        // Calculate the days in this period
        const daysInPeriod = i === 0 ? daysInFirstPeriod : daysBetween(currentDate, nextDate)
        const interestDue = outstandingPrincipal * dailyInterestRate * daysInPeriod
        const principalDue = fixedPaymentAmount - interestDue
        const endingPrincipal = outstandingPrincipal - principalDue

        // Add the payment details to the schedule
        schedule.push({
          date: currentDate,
          daysInPeriod,
          interestDue,
          principalDue,
          paymentAmount: fixedPaymentAmount,
          endingPrincipal
        })

        currentDate = new Date(nextDate)
        nextDate.setMonth(nextDate.getMonth() + 1)

        // Handle overflow when moving from the 31st to shorter months
        if (nextDate.getDate() !== currentDate.getDate()) {
          nextDate.setDate(0) // Set to the last day of the month if overflow
        }

        // Move to the next period
        outstandingPrincipal = endingPrincipal
      }

      offBy = Math.abs(outstandingPrincipal)

      // console.log(JSON.stringify({ offBy, tolerance, fixedPaymentAmount, outstandingPrincipal }))

      if (offBy > tolerance) {
        let pct = outstandingPrincipal / fixedPaymentAmount
        // console.log(`pct=${pct}, offByPct=${offByPct}, adjustmentRatio=${adjustmentRatio}`)
        if (Math.abs(pct) > offByPct) {
          // we're getting further away from the target, so decrease the adjustment ratio
          // eslint-disable-next-line max-len
          // console.log(`off more than previous attempt; decreasing adjustment ratio from ${adjustmentRatio} to ${adjustmentRatio / 2}`)
          adjustmentRatio = adjustmentRatio / 2
        } else if (Math.abs(pct / offByPct) > 0.8 && pct / offByPct < 0) {
          // not converging fast enough; adjustments are too big and we're oscillating over/under
          // eslint-disable-next-line max-len
          // console.log(`oscillating; decreasing adjustment ratio from ${adjustmentRatio} to ${adjustmentRatio / 2}`)
          adjustmentRatio = adjustmentRatio / 2
        } else if (Math.abs(pct / offByPct) > 0.8 && pct / offByPct > 0) {
          // we're converging too slowly but not oscillating; make bigger adjustments
          // eslint-disable-next-line max-len
          // console.log(`not converging fast enough; increasing adjustment ratio from ${adjustmentRatio} to ${adjustmentRatio * 1.2}`)
          adjustmentRatio = adjustmentRatio * 1.2
        }

        offByPct = Math.abs(pct)
        fixedPaymentAmount = fixedPaymentAmount + (pct * adjustmentRatio)
      }
    } while (attempts < 100 && offBy > tolerance)

    if (offBy > tolerance) {
      throw Error(`Failed to calculate payment amount after ${attempts} attempts`)
    }

    // eslint-disable-next-line max-len
    // console.log(`calculated payment amount ${fixedPaymentAmount} in ${attempts} attempts with delta ${offBy}`)

    const sumOfTotalPayments = this.sumOfTotalPayments(schedule)
    const financeCharge = sumOfTotalPayments - loanAmount
    const finalPaymentDate = this.finalPaymentDate(loanDate, term)
    const apr = this.apr(loanAmount, fees, annualInterestRate * 100, term - 6)

    return {
      schedule,
      sumOfTotalPayments,
      payment: fixedPaymentAmount,
      firstPaymentDate,
      finalPaymentDate,
      financeCharge,
      loanDate,
      loanAmount,
      fees,
      apr
    }
  }

  static sumOfTotalPayments(schedule: PaymentScheduleItem[]): number {
    return schedule.reduce((acc, item) => acc + item.paymentAmount, 0)
  }

  static sumOfInterest(schedule: PaymentScheduleItem[]): number {
    return schedule.reduce((acc, item) => acc + item.interestDue, 0)
  }

  static apr(
    amountFinanced: number,
    fees: number,
    interestRate: number,
    numPayments: number
  ): number {
    const monthlyRate = (interestRate / 100) / 12
    const totalLoanAmount = amountFinanced + fees

    // Calculate the monthly payment using the total loan amount (amountFinanced + fees)
    const monthlyPayment = (totalLoanAmount * monthlyRate) /
      (1 - Math.pow(1 + monthlyRate, -numPayments))

    // Iterative binary search to find the APR
    let low = 0 // Minimum APR (0%)
    let high = 100 // Maximum APR (100%)
    let attempts = 0
    const precision = 1e-6 // Precision threshold

    while (high - low > precision) {
      attempts++
      const mid = (high + low) / 2
      const testRate = (mid / 100) / 12 // Convert APR to monthly rate
      const testPayment = (amountFinanced * testRate) /
        (1 - Math.pow(1 + testRate, -numPayments))

      if (testPayment > monthlyPayment) {
        high = mid // Too high, lower the APR
      } else {
        low = mid // Too low, raise the APR
      }
    }

    console.log(`apr=${(high + low) / 2} after ${attempts} attempts`)
    return (high + low) / 2 // Approximate APR
  }
}


import React from 'react'
import { SubformProps } from "."
import ReviewApplication from './review-application'
import { LoanSummary } from '../../components/loan-summary'
import { useAppState } from '../../hooks'

export const SubmitApplication = (props: SubformProps) => {
  const { formData, onSubmit } = props
  const { tenant } = useAppState()

  let rate = `${(formData.useAutoDebit === 'no' ? formData.interest + 0.25 : formData.interest)}%`
  if (formData.incentive) {
    rate = `${formData.incentive.nonIncentiveRate}% before incentives**`
  }

  return <div className="submit-application">
    <p>
      Almost done! Before submitting your application, please review all details for accuracy.
    </p>
    <ReviewApplication {...props} />
    <h2 className="mt-5">Submit Application Request</h2>
    <div className="sa-summary my-4">
      <p>
        You're applying for a {Math.floor(formData.loanDurationMonths / 12)} years loan
        ({formData.loanDurationMonths} months) at a {rate} interest rate.
        Here's a quick summary of your loan details:
      </p>
      <LoanSummary formData={formData} />
    </div>
    {formData.incentive ? <div className="text-small mb-3 fst-italic">
      **With qualifying incentive. Incentive terms can be found <a target="_blank" href={formData.incentive.termsUrl}>here</a>.
    </div> : null}
    <p>
      By submitting your application request you certify that complete and accurate responses have
      been provided for all questions on the application, and agree to allow {tenant.config.name} to
      verify your personal information and check your credit.
    </p>
    <div className="buttons text-start">
      <button
        type="button"
        className="btn btn-primary"
        onClick={() => onSubmit()}
      >Submit Application</button>
      {process.env.REACT_APP_NODE_ENV === 'test' ? <>
        <button
          type="button"
          className="btn btn-secondary"
          onClick={() => onSubmit({ test: true })}
        >Test Submit</button>
      </> : null}
    </div>
    {process.env.REACT_APP_NODE_ENV === 'test' ? <div className="alert alert-secondary mt-3">
      Clicking "Test Submit" will pull DIT, send docs, and simulate the user experience,
      but will NOT actually submit to the lender; e.g., account numbers will be faked.
    </div> : null}
  </div>
}

export default SubmitApplication
import React, { useState } from 'react'
import { SubformProps, Validation } from "."
import { Applicant, Validate } from '@oneethos/shared'
import ApplicantForm from './applicant-form'
import EmploymentForm from './employment-form'
import { Spinner } from '@fluentui/react'
import { errorToString } from '@oneethos/shared'

export const CoborrowerFields = ({ formData, update }: Partial<SubformProps>) => {
  const { prequalResult } = formData

  const handleCoborrowerChange = (key: string, val) => {
    const k = 'co' + key[0].toUpperCase() + key.substring(1)
    update(k, val)
  }

  const showPrompt = prequalResult === 'pass'
  const applicant = Applicant.fromLoanAppCoBorrower(formData)

  return <>
    {/* 
      we only give them the option if the primary borrower passed prequal on their own;
      otherwise it should be required if they've made it to this step
    */}
    {showPrompt ? <div className="form-group">
      <label>Would you like to include a Co-Borrower in your application?</label>
      <select
        className="form-select"
        value={formData.includeCoBorrower}
        onChange={ev => update('includeCoBorrower', ev.target.value)}
      >
        <option value='no'>No</option>
        <option value='yes'>Yes</option>
      </select>
    </div> : null}

    {formData.includeCoBorrower === 'yes' ? <>
      <h2>Co-Borrower Information</h2>
      <ApplicantForm
        applicant={applicant}
        update={handleCoborrowerChange}
        address={formData.coBorrowerAddress || { country: 'United States' }}
        onAddressChange={addr => update('coBorrowerAddress', addr)}
      />
      <h2>Co-Borrower Employment Information</h2>
      <EmploymentForm
        employment={applicant}
        onChange={handleCoborrowerChange}
      />
    </> : null}
  </>
}

export const Coborrower = ({ schema, formData, saving, update, save, onError }: SubformProps) => {
  const [validation, setValidation] = useState<Record<string, string>>({})

  const validate = () => {
    const fields = [
      'coFirstName',
      'coLastName',
      'coSsn',
      'coPhone',
      'coEmail',
      'coBirthDate',
      'coDriversLicenseNo',
      'coDriversLicenseIssueDate',
      'coDriversLicenseIssuingState',
      'coDriversLicenseExpirationDate',
      'coCitizenship',
      'coBorrowerAddress',
      'coOccupation',
      'coEmployerName',
      'coStatedGrossAnnualIncome',
      'coYearsAtEmployer'
    ]

    const results = Validate.validateFields(schema, formData, fields)

    if (!['yes', 'no'].includes(formData.coHasMortgage)) {
      results['hasMortgage'] = 'Please indicate whether or not coborrower has a mortgage'
    }

    return results
  }

  return <>
    <CoborrowerFields formData={formData} update={update} />

    <div>
      <button
        type="button"
        className="btn btn-primary"
        disabled={saving}
        onClick={() => {
          if (formData.includeCoBorrower === 'no') {
            save()
          } else {
            const v = validate()
            setValidation(v)
            if (Object.keys(v).length === 0) {
              save({
                onCatch(ex) {
                  if (ex.error && Array.isArray(ex.error)) {
                    setValidation(ex.error)
                  } else {
                    onError(errorToString(ex))
                  }
                },
              })
            }
          }
        }}
      >{saving ? <Spinner /> : 'Continue'}</button>
    </div>
    {validation ? (
      <Validation validation={validation} /> || <div className="alert alert-info">Form is valid</div>
    ) : null}
  </>
}

export default Coborrower
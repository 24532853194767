import { TenantHostnames } from './tenant-hostnames'
import { AddressInputs, PasswordInput } from '../forms'
import { ImageUploader } from '../'

export const TenantConfig = ({ config, setConfig }) => {
  return (
    <div className="tenant-config">
      <div className="form-group">
        <label>Tenant Name</label>
        <input
          type="text"
          value={config.name}
          className="form-control"
          onChange={ev => setConfig({ ...config, name: ev.target.value })}
        />
      </div>

      <TenantHostnames
        config={config}
        setConfig={setConfig}
      />

      <div className="form-group">
        <label>Enable Residential</label>
        <input type="checkbox"
          onChange={ev => setConfig({ ...config, enableResidential: ev.target.checked })}
          checked={config.enableResidential}
        />
      </div>

      <div className="form-group">
        <label>Enable Commercial</label>
        <input type="checkbox"
          onChange={ev => setConfig({ ...config, enableCommercial: ev.target.checked })}
          checked={config.enableCommercial}
        />
      </div>

      <div className="form-group">
        <label>Enable Non-Profit</label>
        <input type="checkbox"
          onChange={ev => setConfig({ ...config, enableNonProfit: ev.target.checked })}
          checked={config.enableNonProfit}
        />
      </div>

      <div className="d-flex">
        <div>
          <div className="form-group">
            <label>Referral URL</label>
            <input
              type="text"
              value={config.referralUrl}
              className="form-control"
              onChange={ev => setConfig({ ...config, referralUrl: ev.target.value })}
            />
          </div>
        </div>
        <div className="ms-3 flex-1">
          <div className="form-group">
            <label>Commercial Referral URL</label>
            <input
              type="text"
              value={config.referralUrlCommercial}
              className="form-control"
              onChange={ev => setConfig({ ...config, referralUrlCommercial: ev.target.value })}
            />
            <p className="small text-secondary">
              Do not include trailing "?", parameters will be appended automatically
            </p>
          </div>
        </div>
      </div>

      <div className="form-group">
        <label>Rate Effective Date</label>
        <input
          type="text"
          value={config.rateEffectiveDate || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, rateEffectiveDate: ev.target.value })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="bankEmail">Bank Email</label>
        <input
          type="email"
          autoComplete='off'
          value={config.bankEmail || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, bankEmail: ev.target.value })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="bankEmail">Bank Phone</label>
        <input
          type="text"
          autoComplete='off'
          value={config.bankPhone || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, bankPhone: ev.target.value })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="bankNotifyAppSubmittedEmail">Bank Notify App Submitted Email</label>
        <input
          type="email"
          autoComplete='off'
          value={config.bankNotifyAppSubmittedEmail || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, bankNotifyAppSubmittedEmail: ev.target.value })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="bankEmail">Installer Support Email</label>
        <input
          type="email"
          autoComplete='off'
          value={config.installerSupportEmail}
          className="form-control"
          onChange={ev => setConfig({ ...config, installerSupportEmail: ev.target.value })}
        />
      </div>

      <div className="form-group">
        <label htmlFor="zendeskEmail">Document Upload Notification Email</label>
        <input
          type="email"
          autoComplete='off'
          value={config.zendeskToEmail}
          className="form-control" id="zendeskEmail"
          onChange={ev => setConfig({ ...config, zendeskToEmail: ev.target.value })}
        />
      </div>

      <div className="form-group">
        <label>Doc Upload From Email</label>
        <input
          type="email"
          autoComplete='off'
          value={config.docUploadFromEmail}
          className="form-control"
          onChange={ev => setConfig({ ...config, docUploadFromEmail: ev.target.value })}
        />
      </div>

      <AddressInputs
        onChange={ev => setConfig({ ...config, primaryAddress: ev })}
        address={config.primaryAddress}
      />

      <ImageUploader
        label="Logo"
        src={config.logo ? `/api/tenant/logo` : undefined}
        onChange={doc => {
          setConfig({ ...config, logo: doc })
        }}
        onRemove={() => setConfig({ ...config, logo: null })}
      />

      <ImageUploader
        label="Letterhead Logo"
        src={config.letterheadLogo ? `/api/tenant/letterhead-logo` : undefined}
        onChange={doc => {
          setConfig({ ...config, letterheadLogo: doc })
        }}
        onRemove={() => setConfig({ ...config, letterheadLogo: null })}
      />
      <p>Primary logo will be used if no letterhead logo is configured</p>

      <div className="form-group">
        <label>Marketing Domain</label>
        <input
          type="text"
          autoComplete='off'
          value={config.marketingDomain || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, marketingDomain: ev.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Training Page Iframe URL</label>
        <input
          type="url"
          autoComplete='off'
          value={config.trainingPageUrl || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, trainingPageUrl: ev.target.value })}
        />
      </div>
      <div className="form-group">
        <label>eSign Agreement URL</label>
        <input
          type="url"
          autoComplete='off'
          value={config.esignAgreementUrl || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, esignAgreementUrl: ev.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Privacy Policy URL</label>
        <input
          type="url"
          autoComplete='off'
          value={config.privacyPolicyUrl || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, privacyPolicyUrl: ev.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Terms and Conditions URL</label>
        <input
          type="url"
          autoComplete='off'
          value={config.termsAndConditionsUrl || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, termsAndConditionsUrl: ev.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Adverse Action Webhook URL</label>
        <input
          type="text"
          value={config.adverseActionWebhookUrl || ''}
          className="form-control"
          onChange={ev => setConfig({ ...config, adverseActionWebhookUrl: ev.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Document Upload Webhook URL</label>
        <input
          type="text"
          value={config.docUploadWebhookUrl}
          className="form-control"
          onChange={ev => setConfig({ ...config, docUploadWebhookUrl: ev.target.value })}
        />
      </div>
      <div className="form-group">
        <label>Document Upload Webhook Secret</label>
        <PasswordInput
          value={config.docUploadWebhookSecret}
          onChange={val => setConfig({ ...config, docUploadWebhookSecret: val })}
        />
      </div>
    </div>
  )
}
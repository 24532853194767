import React, { useState } from 'react'
import { FormInput, SubformProps } from "."
import { getBrowserFingerprintData } from "../../browser-fingerprint"
import { Spinner } from '@fluentui/react'
import { AddressInputs, MustClickLink } from '../../components'
import { StreetAddress, Validate, isServiceableAddress, Audience, AgreementType } from '@oneethos/shared'
import { useAppState } from '../../hooks'
import api from '../../api-client'
import { toast } from 'react-toastify'
import * as Sentry from "@sentry/react"
import { LoanSummary } from '../../components/loan-summary'

const { checkDollarValue } = Validate

export const InitialLoanRequest = ({ formData, org, update, save, saving }: SubformProps) => {
  const { state, country } = (formData.installationAddress || {})
  const { tenant: { config } } = useAppState()

  // the UI prevents them from accepting the terms without clicking the links,
  // so we can safely assume that if they're returning to this page and previously 
  // agreed to terms, they've clicked the links
  const agrees = formData.agreesToTermsAndConditions?.agrees
  const [hasClickedTerms, setHasClickedTerms] = useState(agrees)
  const [hasClickedPrivacy, setHasClickedPrivacy] = useState(agrees)
  const [hasClickedEsign, setHasClickedEsign] = useState(agrees)
  const [savingAgreements, setSavingAgreements] = useState(false)

  const {
    termsAndConditionsUrl = "https://uploads-ssl.webflow.com/635ae89b651fdf01ef0360a7/63fd1bb51695e86a2e73a542_FINAL-Terms-and-Conditions-Climate-First_Accessible.pdf",
    privacyPolicyUrl = "https://uploads-ssl.webflow.com/635ae89b651fdf01ef0360a7/63fd1b125c945e62586bc929_FINAL-Privacy-Policy-Accessible.pdf",
    esignAgreementUrl = "https://uploads-ssl.webflow.com/635ae89b651fdf01ef0360a7/63fd34e2f8e1cd8416c96c95_6251ac8b953d1e2a1dfee967_eSign.pdf"
  } = config

  const hasClickedAll = hasClickedTerms && hasClickedPrivacy && hasClickedEsign

  return <div>
    <h2>Solar Loan Application</h2>
    <div className="alert alert-info">
      Please review the following information carefully. If anything appears incorrect, please
      contact your installer to request a new referral before proceeding with your loan application.
    </div>
    <LoanSummary formData={formData} />
    <AddressInputs
      address={formData.installationAddress || {}}
      label="Address where solar panels will be installed"
      onChange={address => {
        update({
          ...formData,
          installationAddress: address,
          userAddress: address
        })
      }}
    />
    {state ? <>
      {!StreetAddress.isValid(formData.installationAddress) ? <div>
        <div className="alert alert-info">
          Please confirm you have entered a complete address.
        </div>
      </div> : <>
        {!isServiceableAddress(state, country, org) ? <div className="alert alert-warning">
          We're sorry, we do not yet support projects at your address.
        </div> : <>
          <FormInput
            field='avgMonthlyUtilityBill'
            value={formData.avgMonthlyUtilityBill}
            onChange={s => update('avgMonthlyUtilityBill', s)}
          />
          <p> Please review our&nbsp;
            <MustClickLink
              url={esignAgreementUrl}
              clicked={hasClickedEsign}
              label="eSign Agreement"
              onClicked={() => {
                setHasClickedEsign(true)
              }}
            />,
            &nbsp;
            <MustClickLink
              url={privacyPolicyUrl}
              clicked={hasClickedPrivacy}
              label="privacy policy"
              onClicked={() => {
                setHasClickedPrivacy(true)
              }}
            />,
            &nbsp;and&nbsp;
            <MustClickLink
              url={termsAndConditionsUrl}
              clicked={hasClickedTerms}
              label="terms and conditions"
              onClicked={() => {
                setHasClickedTerms(true)
              }}
            />
            &nbsp;
            <br />
            <br />
            <b>IMPORTANT INFORMATION ABOUT PROCEDURES FOR OPENING A NEW ACCOUNT</b>
            <br />
            To help the government fight the funding of terrorism and money laundering
            activities, Federal law requires all financial institutions to obtain, verify
            and record information that on personal accounts identifies each person who
            opens an account. In addition, on legal entity accounts, we will require
            identification on beneficial owners and controlling person. What this means
            for you: When you open an account, we will ask for your name, address, date of
            birth, and other information that will allow us to identify you. We may also
            ask to see your driver’s license or other identifying documents.
          </p>
          <div className="form-group" style={{ cursor: 'pointer' }}>
            <label>
              <input type="checkbox"
                checked={formData.agreesToTermsAndConditions?.agrees || false}
                disabled={!hasClickedAll}
                onChange={ev => update('agreesToTermsAndConditions', ev.target.checked ? {
                  browser: getBrowserFingerprintData(),
                  timestamp: new Date(),
                  agrees: true
                } : false)}
              />
              &nbsp;
              <b>I have read and agree with eSign Agreement,
                privacy policy, and terms and conditions.</b>
            </label>
          </div>
          {(
            formData.loanAmount &&
            formData.agreesToTermsAndConditions &&
            checkDollarValue(formData.avgMonthlyUtilityBill) === ''
          ) ? (
            <div className="buttons">
              <button type="button"
                className="btn btn-primary"
                disabled={saving}
                onClick={async () => {
                  setSavingAgreements(true)
                  const browser = getBrowserFingerprintData()

                  try {
                    // ensure all consents are recorded before proceeding
                    await Promise.all([
                      { url: esignAgreementUrl, type: AgreementType.EsignAgreement },
                      { url: privacyPolicyUrl, type: AgreementType.PrivacyPolicy },
                      { url: termsAndConditionsUrl, type: AgreementType.ConsumerTerms }
                    ].map(agreement => api.post('/consents/accept', {
                      browser,
                      agrees: true,
                      agreementUrl: agreement.url,
                      agreementType: agreement.type,
                      audience: Audience.Consumer,
                      loanApplicationId: formData._id
                    })))
                    setSavingAgreements(false)

                    await save()
                  } catch (ex) {
                    setSavingAgreements(false)
                    toast.error('Error recording agreements, please try again. If the problem persists, please contact support.')
                    Sentry.captureException(ex, {
                      tags: {
                        error: 'Error recording agreements'
                      }
                    })
                  }
                }}
              >{saving || savingAgreements ? <Spinner /> : 'Continue'}</button>
            </div>
          ) : <div className="alert alert-secondary">
            Please confirm address, review and agree to terms, and provide average
            monthly utility bill to proceed to the next step.
          </div>}
        </>}
      </>}
    </> : null}
  </div>
}

export default InitialLoanRequest
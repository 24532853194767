import { useState, useEffect } from 'react'
import './calculator-page.scss'
import { Spinner } from '@fluentui/react'
import api from '../api-client'
import { Section, LoanInformation, RadioToggle, LoanDetails } from '../components'
import { useAppState, useUtmQuery } from '../hooks'
import {
  errorToString,
  dollars,
  dollarsToNum,
  isServiceableAddress,
  StreetAddress,
  ITenant,
  Incentive,
  LoanCalculations,
  AdderTypes,
  IProduct,
  Validate,
} from '@oneethos/shared'
import { AddressInputs, DollarsNonZeroInput, NumericInput, TextInput, SignedDollarsInput } from '../components/forms'
import { FaBuilding, FaHandHoldingHeart, FaHouseChimney } from 'react-icons/fa6'
import { toast } from 'react-toastify'
import { LoanSummary } from '../components/loan-summary'

type PaymentCalculatorProps = {
  formData?: any
  isResidential: boolean
  onChange?: (formData: any) => void
  showCalculation?: boolean
  onShowCalculation?: () => void
  financingTenant?: ITenant
  incentive?: Incentive
  loadingCalculation?: boolean
  interestRate?: number
  loanTermMonths?: number
}

const LoanDetailLogos = ({ financingTenant, incentive }) => {
  return <div className="mt-6 d-flex">
    <div className="form-group">
      <label>Financing Provided By:</label>
      {/* TODO: update cache-busting */}
      <img src={`/api/tenant/logo/${financingTenant._id}`} loading="lazy" id="logo"
        sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
        alt="financing provider logo"
        style={{ maxHeight: '50px' }}
      />
    </div>

    {incentive ? <div className="form-group">
      <label>In partnership with:</label>
      <img src={incentive.financingPartnerLogo} loading="lazy" id="logo"
        sizes="(max-width: 479px) 83vw, (max-width: 767px) 72vw, 397.8984375px"
        alt="local financing partner logo"
        style={{ maxHeight: '50px' }}
      />
    </div> : null}
  </div>
}

type LoanDetailsProps = {
  formData: any
  financingTenant: ITenant
  incentive: Incentive
  rateEffectiveDate: string
  loanPlusClosingCosts: number
  isResidential: boolean
  product: IProduct
}

const IncentiveLoanDetails = ({ incentive, financingTenant, }: LoanDetailsProps) => {
  return <div className="incentive-loan-details">
    <LoanDetailLogos
      financingTenant={financingTenant}
      incentive={incentive}
    />

    <div className="savings" dangerouslySetInnerHTML={{ __html: incentive.savingsHTML }}></div>
    <div className="inc-descr" dangerouslySetInnerHTML={{ __html: incentive.incentiveDescription }}></div>
    <div className="detail-wrap">
      <div className="loan-detail" dangerouslySetInnerHTML={{ __html: incentive.loanDetailHTML }}></div>
      <div className="loan-tbl" dangerouslySetInnerHTML={{ __html: incentive.loanTableHTML }}></div>
    </div>
  </div>
}

const PaymentCalculator = ({
  formData,
  isResidential,
  onChange,
  onShowCalculation,
  financingTenant,
  incentive,
  loadingCalculation,
}: PaymentCalculatorProps) => {
  const {
    registration: { installer },
    tenant: { config }
  } = useAppState()

  const { rateEffectiveDate, products } = config
  const [hasTouchedSystemSize, setHasTouchedSystemSize] = useState(false)
  const [shouldShowValidation, setShouldShowValidation] = useState(false)

  const addressIsValid = StreetAddress.isValid(formData.installationAddress)
  const solarCostIsValid = formData.solarCost && formData.solarCost !== '$0.00'
  const systemSizeIsValid = formData.systemSizeKW > 0
  const downpaymentIsValid = !formData.adders?.some(adder =>
    adder.description === AdderTypes.Downpayment &&
    dollarsToNum(adder.amount) >= formData.systemCost)

  const minimumSolarPrice = isResidential ? 10000 : 25000
  const solarCostNumeric = dollarsToNum(formData.solarCost)
  const amountSolarPriceIsValid = solarCostNumeric >= minimumSolarPrice
  const customMessage = `The minimum solar price is ${dollars(minimumSolarPrice)}`

  const validationMessage = [
    { isValid: addressIsValid, message: 'Please provide a valid Address.', value: formData.installationAddress },
    { isValid: solarCostIsValid, message: 'Please provide a valid Solar Price.', value: formData.solarCost },
    { isValid: systemSizeIsValid, message: 'Please provide a valid System Size.', value: formData.systemSizeKW },
  ]

  const getAdderValidationMessage = (
    adderType: string,
    amount: string | undefined,
    solarCost: string
  ): string => {
    if (adderType === AdderTypes.Downpayment && amount && solarCost) {
      return dollarsToNum(amount) >= dollarsToNum(solarCost)
        ? 'Value must be less than the total system cost'
        : null
    }
    return null
  }
  
  const enableCalculationButton = (
    addressIsValid &&
    solarCostIsValid &&
    systemSizeIsValid &&
    amountSolarPriceIsValid &&
    downpaymentIsValid
  )

  useEffect(() => {
    if (formData.installationAddress && formData.solarCost && formData.systemSizeKW) {
      setShouldShowValidation(true)
    } else {
      setShouldShowValidation(false)
    }
  }, [formData.installationAddress, formData.solarCost, formData.systemSizeKW])

  const handleChange = (nextData) => {
    const size = parseFloat(nextData.systemSizeKW) || 0
    const solar = dollarsToNum(nextData.solarCost || '') || 0

    const ppw = size && solar ? dollars(solar / (size * 1000)) : ''
    const validAdders = Validate.getValidAdders(nextData.adders)
    const adders = Validate.normalizeAdders(validAdders)
    const systemCost = dollars(LoanCalculations.calcSystemCost(solar, adders))
    const loanAmount = dollars(LoanCalculations.calcLoanAmount(solar, adders))

    onChange({
      ...nextData,
      systemCost: systemCost,
      loanAmount: loanAmount,
      ppWForPV: ppw
    })
  }

  const product = products.find(p => p.productType === formData?.projectType)
  const { state, country } = (formData.installationAddress || {})
  const { systemCost, loanAmount } = formData
  const loanAmountNumeric = dollarsToNum(loanAmount)
  const fees = LoanCalculations.estimatedClosingCosts(
    loanAmountNumeric,
    state,
    formData.projectType
  )
  const loanPlusClosingCosts = loanAmountNumeric + fees

  const loanDetailsProps = {
    formData,
    financingTenant,
    incentive,
    product,
    rateEffectiveDate,
    loanPlusClosingCosts,
    isResidential,
  }

  const LoanDetailsComponent = incentive?.financingPartner ? IncentiveLoanDetails : LoanDetails

  return <Section>
    <div className="section-title">
      <h2 className="margin-bottom-0">Loan Monthly Payment Calculator</h2>
    </div>
    <div className="form-group">
      <label className="my-2 mb-3">Select Project Type</label>
      <RadioToggle
        value={formData.projectType}
        onChange={v => handleChange({ ...formData, projectType: v })}
        options={[
          { label: 'Residential', value: 'residential', Icon: FaHouseChimney },
          { label: 'Commercial', value: 'commercial', Icon: FaBuilding },
          { label: 'Non-Profit', value: 'non-profit', Icon: FaHandHoldingHeart },
        ]}
      />
    </div>

    {!formData.projectType ? null : <>
      {isResidential ? null : <div className="form-group">
        <label>Business or Non-Profit Name</label>
        <TextInput
          value={formData.businessName}
          onChange={val => handleChange({ ...formData, businessName: val })}
        />
      </div>}

      <AddressInputs
        address={formData.installationAddress || {}}
        label="Address where solar panels will be installed"
        onChange={address => handleChange({
          ...formData,
          installationAddress: address,
        })}
      />

      {
        (
          isResidential &&
          StreetAddress.isValid(formData.installationAddress) &&
          !isServiceableAddress(state, country, installer.org)
        ) ? (
          <div className="alert alert-danger">
            <b>Warning:</b> the consumer will be unlikely to complete the application for this
            address as it is outside the supported area. For more information, please contact
            your account representative.
          </div>
        ) : null
      }
      <h4>System Details</h4>
      <div className="system-details">
        <div className="system-prices">
          <div className="form-group">
            <label>Solar Price</label>
            <DollarsNonZeroInput
              placeholder="$10,000"
              extravalidationmessage={amountSolarPriceIsValid ? '' : customMessage}
              value={formData.solarCost}
              onChange={v => handleChange({
                ...formData,
                solarCost: v
              })}
            />
          </div>
          <div className='adders-container'>
            {Object.values(AdderTypes).map(adderType => {
              const isDownpayment = adderType === AdderTypes.Downpayment
              return (
                <div key={adderType} className="form-group">
                  <label>{adderType}</label>
                  <SignedDollarsInput
                    placeholder="$0.00"
                    emptyOk
                    isNegative={isDownpayment}
                    value={formData.adders?.find(a => a.description === adderType)?.amount}
                    onChange={v => handleChange({
                      ...formData,
                      adders: [
                        ...(formData.adders?.filter(a => a.description !== adderType) || []),
                        { description: adderType, amount: v }
                      ]
                    })}
                    extravalidationmessage={getAdderValidationMessage(
                      adderType,
                      formData.adders?.find(a => a.description === adderType)?.amount,
                      formData.solarCost
                    )}
                  />
                </div>
              )
            })}
          </div>
        </div>
        <div className="system-size">
          <div className="form-group">
            <label>System Size (kW)</label>
            <NumericInput
              value={formData.systemSizeKW}
              onBlur={() => setHasTouchedSystemSize(true)}
              onChange={v => handleChange({
                ...formData,
                systemSizeKW: v
              })}
            />
          </div>
          <div className="form-group">
            <label>Price Per Watt</label>
            <TextInput
              disabled
              value={formData.ppWForPV}
            />
          </div>
          <div className="form-group">
            <label><b className='text-dark'>Total System Cost</b></label>
            <TextInput
              value={systemCost}
              disabled
            />
          </div>
          <div className="form-group">
            <label><b className='text-dark'>Requested Loan Amount</b></label>
            <TextInput
              value={loanAmount}
              disabled
            />
          </div>
        </div>
      </div>
      {hasTouchedSystemSize && formData.ppWForPV && dollarsToNum(formData.ppWForPV) > 3.75 ? (
        <div className="alert alert-danger">
          Loan applications with PPW higher than $3.75 are unlikely to be approved.
        </div>
      ) : null}
      {financingTenant ? <div className="fin-tenant">
        <LoanDetailsComponent {...loanDetailsProps} />
      </div> : <div style={{ textAlign: 'center' }}>
        {loadingCalculation ? <Spinner /> : <>
          <div>
            {shouldShowValidation && validationMessage.map((error, index) => (
              error.value && !error.isValid && (
                <div key={index} className="alert alert-info">
                  <div>{error.message}</div>
                </div>
              )
            ))}
          </div>
          <button
            type="button"
            className="btn btn-primary"
            disabled={!enableCalculationButton}
            onClick={() => onShowCalculation()}
          >Calculate Payment</button>
        </>}
      </div>}
    </>}
  </Section >
}

const SendApplicationLink = ({ formData, isResidential, incentive }: PaymentCalculatorProps) => {
  const [email, setEmail] = useState('')
  const [sending, setSending] = useState(false)
  const [error, setError] = useState('')
  const [success, setSuccess] = useState('')
  const [referralId, setReferralId] = useState('')

  const { tenant: { config } } = useAppState()
  const { products } = config

  const product = products?.find(p => p.productType === formData?.projectType)
  const interest = product?.interestRateWithAutopay
  const loanTerm = product?.term

  const utm = useUtmQuery()
  const { systemCost } = formData

  const submitReferral = () => {
    setError('')
    setSuccess('')

    if (Validate.isValidEmail(email) !== "") {
      setError('Please enter a valid email address')
      return
    } else if (!systemCost) {
      setError('Please enter a valid loan amount')
      return
    }

    const validAdders = Validate.getValidAdders(formData.adders)
    const adders = Validate.normalizeAdders(validAdders)

    setSending(true)
    api.post('/loanapps', {
      ...utm,
      email,
      productId: product?._id,
      projectType: formData.projectType,
      businessName: formData.businessName || '',
      interest,
      loanDurationMonths: loanTerm,
      incentive,
      installationAddress: formData.installationAddress,
      userAddress: formData.installationAddress,
      solarCost: dollarsToNum(formData.solarCost),
      systemSizeKW: parseFloat(formData.systemSizeKW),
      systemCost: dollarsToNum(formData.systemCost),
      loanAmount: dollarsToNum(formData.loanAmount),
      ppWForPV: dollarsToNum(formData.ppWForPV),
      adders: adders,
    }).then(res => {
      if (res.message) {
        setSuccess(res.message)
      } else {
        setSuccess("Thank you for submitting your request. An email confirmation will be sent to your client shortly.")
      }

      if (res._id) {
        setReferralId(res._id)
      }
    }).catch(ex => {
      setSuccess('')
      setError(errorToString(ex))
    }).finally(() => setSending(false))
  }

  if (success) {
    return <div>
      <div className="alert alert-success">{success}</div>

      {referralId ? <div>
        <h3>Has the consumer already signed a contract?</h3>
        <p>Visit the project page to upload the signed contract and avoid delays</p>
        <div className="buttons">
          <a className="btn btn-primary" href={`/project/${referralId}?v=checklist`}>View Project Page</a>
        </div>
      </div> : null}
    </div>
  }

  return <div>
    <h2 className="margin-bottom-medium">Send Application Link </h2>
    <div className="w-form">
      <form id="email-form-2" name="email-form-2" data-name="Email Form 2" method="get">
        <LoanSummary formData={formData} />
        {systemCost >= 125_000 && isResidential ? <div className="alert alert-info">
          Loans larger than $125,000 will require additional income verification
        </div> : null}
        <div className="form-group">
          <label>Enter client email address</label>
          <input type="email"
            className="form-control"
            maxLength={256}
            name="email"
            data-name="email"
            placeholder="client@email.com"
            id="email"
            autoComplete="off"
            value={email}
            onChange={ev => setEmail(ev.target.value)}
          />
        </div>
        <div className="buttons form-group">
          {sending ? <Spinner /> : <button
            type="button"
            className="btn btn-primary"
            onClick={submitReferral}
          >Send Referral Link</button>}
        </div>
        {error ? <div className="alert alert-danger">{error}</div> : null}
      </form>
    </div>
  </div >
}

export const CalculatorPage = () => {
  const [formData, setFormData] = useState<any>({ systemCost: '0', loanAmount: '0' })
  const [showCalculation, setShowCalculation] = useState<boolean>(false)
  const [financingTenant, setFinancingTenant] = useState(null)
  const [incentive, setIncentive] = useState()
  const [loadingCalculation, setLoadingCalculation] = useState(false)
  const { tenant: { config } } = useAppState()

  const product = config.products?.find((product: IProduct) =>
    product.productType === formData.projectType)

  const interestRate = product?.interestRateWithAutopay
  const loanTermMonths = product?.term

  const isResidential = !['commercial', 'non-profit'].includes(formData.projectType)


  return <div className="calculator-page">
    <PaymentCalculator
      formData={formData}
      financingTenant={financingTenant}
      isResidential={isResidential}
      onChange={data => {
        // change in address or system price requires recalculation
        setFinancingTenant(null)
        setShowCalculation(false)
        setIncentive(undefined)
        setFormData(data)
      }}
      showCalculation={showCalculation}
      incentive={incentive}
      loadingCalculation={loadingCalculation}
      onShowCalculation={() => {
        const query = new URLSearchParams({
          ...formData.installationAddress,
          projectType: formData.projectType,
          systemCost: dollarsToNum(formData.systemCost),
          interestRate,
          loanTermMonths
        })

        setLoadingCalculation(true)
        api.get(`/tenant/for-project?${query.toString()}`).then(res => {
          if (parseFloat(res.incentive?.estPriceWithIncentives) > 0 && isResidential) {
            setIncentive(res.incentive)
          }

          if (res.tenant) {
            setFinancingTenant(res.tenant)
          }

          setShowCalculation(true)
        }).catch(ex => toast.error(errorToString(ex)))
          .finally(() => setLoadingCalculation(false))
      }}
    />
    {showCalculation ? <>
      {incentive ? null : <LoanInformation isResidential={isResidential} />}
      <Section>
        <SendApplicationLink
          formData={formData}
          isResidential={isResidential}
          incentive={incentive}
        />
      </Section>
    </> : null}
  </div>
}

export default CalculatorPage

import { Document, mongo } from 'mongoose'
import {
  Dictionary,
  Installer,
  StreetAddress,
  LoggedUpload,
  LoanDocument,
  SolarDoc,
  CitizenshipValue,
  ITenant,
  Org,
  Individual,
  SIndividual,
  ApplicantRace,
  IncomeDocument,
  Incentive,
  FundReleaseRequest,
  Consent
} from './index'
import { StatusData } from './status-data'
import { PreSubmitStatus, PreSubmitUtils } from './presubmit-status'
import { Applicant } from './applicant'

export type StatusTokenPayload = {
  id: string
  uploadType?: string
  role?: 'applicant' | 'installer'
}

export type AirtableUserMeta = {
  id?: string
  email?: string
  name?: string
}

export type StepDocStatus = {
  requiredDocsCount: number
  approvedDocsCount: number
  docsNeedSupportActionCount: number
  docsNeedInstallerActionCount: number
  allDocsUploadedForStep: boolean
}

export type ProjectType = 'residential' | 'commercial' | 'non-profit'

export enum AdderTypes {
  RoofPrice = 'Roof Price',
  StoragePrice = 'Storage Price',
  TreeRemovalTrimmingsPrice = 'Tree Removal or Trimmings Price',
  GroundMountPrice = 'Ground Mount',
  PergolaPrice = 'Pergola Price',
  CarpotPrice = 'Carport Price',
  HvacPrice = 'HVAC Price',
  Downpayment = 'Downpayment'
}

export type InstallerDocMap = Dictionary<Dictionary<LoanDocument>>

export type PrequalResult = 'pass' | 'fail' | 'no-hit'
export type DitDecision = 'Approve' | 'Deny' | 'Review' | 'Error'

// not known if this is comprehensive list
export type Decision = "Approved-Pending Signed Contract" |
  "Approved" |
  "Booked (Solar + Roof)" |
  "Booked (Solar Only)" |
  "Canceled" |
  "Change Order Pending" |
  "Declined" |
  "Fully Funded" |
  "Loan Docs Requested" |
  "Partially Funded" |
  "Pending Phase 1 Funding" |
  "Pending Phase 2 Funding" |
  "Pending Roof Funding" |
  "Pending" |
  "Roof Funded" |
  "Withdrawn" |
  null

export interface BaseLoanApplication extends Applicant {
  _id: string
  address: string
  airtableId: string
  appId?: number
  approvalDate?: Date
  applicationReceived: Date
  authorizedInstallers?: string[]
  avgMonthlyUtilityBill?: number
  customerName?: string
  businessName?: string
  projectType?: ProjectType
  principals?: Partial<Individual>[]
  incentive?: Incentive
  employerName: string
  occupation: string
  statedGrossAnnualIncome: string // "$100,000.00"
  sourceLoanApplicationId?: typeof mongo.ObjectId
  loanNumber: string
  loanAccountNumber: string
  
  id: string
  installersStatus: string
  email: string
  loanDurationMonths: number
  loanAmount: number
  solarCost?: number
  roofCost?: number
  storageCost?: number
  systemCost?: number
  otherCost?: number
  systemSizeKW?: number
  ppWForPV?: number
  interest: number
  installerEmail?: string[]
  installerID: string
  source?: {
    kind: 'web' | 'api'
    integrationId: string
    key: string
  }
  branchId: string
  campaign: string
  decision?: string
  uploads?: LoggedUpload[]
  userAddress?: StreetAddress
  citizenship: CitizenshipValue
  race?: ApplicantRace
  statedHouseholdIncome?: string // "$100,000.00"
  coBorrowerAddress?: StreetAddress
  includeCoBorrower?: 'yes' | 'no'
  installationAddress?: StreetAddress
  aaNoticeGiven?: string
  nTPGiven?: string
  installedAtDifferentLocation?: 'yes' | 'no'
  incomeDocType?: 'paystubs' | 'W-2' | 'taxreturn'
  incomeVerificationDocuments?: SolarDoc[]
  useAutoDebit?: 'yes' | 'no'
  autoDebitAccountNo?: string
  autoDebitRoutingNo?: string
  autoDebitInstitution?: string
  agreesToTermsAndConditions?: Consent
  agreesToProceedWithHardCreditInquiry?: Consent
  stateInstalled?: string
  propertyHeldInTrust?: 'yes' | 'no'
  previousEmployment?: string
  yearsAtEmployer?: number
  fundReleasesRequested?:FundReleaseRequest[]

  coFirstName: string
  coMiddleName: string
  coLastName: string
  coSsn: string
  coPhone: string
  coEmail: string
  coBirthDate: string
  coDriversLicenseNo: string
  coDriversLicenseIssueDate: string
  coDriversLicenseIssuingState: string
  coDriversLicenseExpirationDate: string
  coCitizenship: CitizenshipValue
  coRace?: ApplicantRace
  coEmployerName: string
  coOccupation: string
  coYearsAtEmployer: string
  coPreviousEmployment: string
  coIncomeDocType?: 'paystubs' | 'W-2' | 'taxreturn'
  coIncomeVerificationDocuments?: SolarDoc[]
  coStatedGrossAnnualIncome: string // "$100,000.00" used for prequal
  coStatedHouseholdIncome?: string // "$100,000.00" reporting purposes only, not used for prequal
  coHasMortgage?: 'yes' | 'no'
  lookup?: string

  abrigoProposedLoanId?: number
  abrigoCoborrowerLoanRoleId?: number
  abrigoLoanApplicationId?: number
  abrigoWorkflowId?: number
  error: string

  isLocked?: boolean
  lockedTimestamp?: Date
  prequalResult?: PrequalResult
  prequalTimestamp?: Date
  prequalResultCombined?: PrequalResult
  prequalError?: string
  declinedToAddCoborrowerDate?: Date
  ditDecision?: DitDecision
  coDitDecision?: DitDecision
  hasOfacAlerts?: boolean
  ditError?: string
  preSubmitStatus?: PreSubmitStatus
  submissionResponse?: any,
  submittedFromIp?: string
  notesVisibleToInstallers?: string

  // should be duplicates of branchId, installerID, and campaign respectively
  utm_medium: string
  utm_source: string
  utm_campaign: string

  rim: string
  installerDocs?: InstallerDocMap
  envelopes?: {
    envelopeId: string,
    timestamp: Date
  }[]
  pendingBookingDate?: string // from airtable; important; tells us loan docs are signed
  loanDocsSignedDate?: Date // future when we have from docusign
  loanDocsIssuedDate?: string // from airtable; will be set initially when sending docs via docusign
  lastStatusChange?: Date
  lastModified?: Date
  lastModifiedBy?: AirtableUserMeta
  createdDate?: Date
  updatedDate?: Date
  preapprovalLetter?: {
    doc: SolarDoc
    contentMD5: string
    agreement?: Consent
  }

  // V1 new fields
  productId: string
  projectUrl?: string
  loanProposalId: string
  loanRequestId: string
  monthlyAverageEnergy: number
  batteryCost: number
  treeRemovalCost: number
  adders: [{
    description: string
    amount: number
  }]

  getStatusData: () => StatusData
  addOrUpdatePrincipal(p: Individual): SIndividual
  getReferralToken: () => string
  getStreet: () => string
  getDoc: (id: string) => LoanDocument | undefined
  getStepDocStatus: () => StepDocStatus
  isResidential: () => boolean
  isDeclined: () => boolean
  isJointApp: () => boolean
  processDecline: () => Promise<void>
  lastUploadForStatus(status: string): LoggedUpload | undefined
  logUpload(
    uploadType: string,
    documents: LoanDocument[],
    uploadedBy?: string,
    notes?: string
  ): Promise<void>
  projectAddress: () => string
  estimatedPayment: () => string
  estimatedClosingCosts: () => number
  unsetSensitiveData: () => void
  renderableData: (t?: ITenant, org?: Org) => Record<string, string>
  hasMinPrequalInfo: () => boolean
  hasMinCoborrowerPrequalInfo: () => boolean
  validateConsumerApplication_incomplete: () => string[]
}

export type LoanApplication = BaseLoanApplication & Document

// servier loan application
export type SLoanApplication = LoanApplication & {
  _id: typeof mongo.ObjectId
  creditReports: typeof mongo.ObjectId[]
  referralSubmittedBy: typeof mongo.ObjectId | string | Installer
  toMaskedObject(): Partial<SLoanApplication>
  updateConsumerAssignableFields(o: Partial<SLoanApplication>): void
  getPresubmitErrors(): string[]
  getLookupHash(): string
  getCollateralDescription(): string
  addEnvelope(envelope: { envelopeId: string, timestamp: Date }): void
  shouldAutoSendLoanDocs(): boolean
}

// client loan application
export type CLoanApplication = LoanApplication & {
  consumerId: string
  _id: string
}

export type SLoanAppField = keyof SLoanApplication
export type CLoanAppField = keyof CLoanApplication
export type LoanAppField = keyof LoanApplication

export const getIncomeDocErrors = (lapp: LoanApplication): string[] => {
  const {
    coIncomeVerificationDocuments: coDocs,
    incomeVerificationDocuments: docs,
    coIncomeDocType,
    incomeDocType,
    includeCoBorrower
  } = lapp

  const errors = []

  if (PreSubmitUtils.isAfter('Co-Borrower', lapp.preSubmitStatus)) {
    if (incomeDocType === 'paystubs' && docs?.length !== 2) {
      errors.push('Exactly two paystub documents required')
    } else if (['W-2', 'taxreturn'].includes(incomeDocType) && docs?.length !== 1) {
      errors.push('Exactly one W-2 or one tax return document required')
    } else if (!incomeDocType) {
      errors.push('Income verification documents required')
    }

    if (includeCoBorrower === 'yes') {
      if (coIncomeDocType === 'paystubs' && coDocs?.length !== 2) {
        errors.push('Exactly two paystub documents required for co-borrower')
      } else if (['W-2', 'taxreturn'].includes(coIncomeDocType) && coDocs?.length !== 1) {
        errors.push('Exactly one W-2 or one tax return document required for co-borrower')
      } else if (!coIncomeDocType) {
        errors.push('Co-borrower income verification documents required')
      }
    }
  }
  return errors
}

export const getIncomeDocErrorsMultiUpload = (
  lapp: LoanApplication,
  docsArray: IncomeDocument[],
  coDocsArray: IncomeDocument[]
): string[] => {
  const {includeCoBorrower} = lapp

  const errors: string[] = []

  if (PreSubmitUtils.isAfter('Co-Borrower', lapp.preSubmitStatus)) {
    if (docsArray) {
      docsArray.forEach((docSet) => {
        if (docSet.docType === 'paystubs' && docSet.documents?.length !== 2) {
          errors.push(`Exactly two paystub documents required for primary borrower`)
        } else if (['W-2', 'taxreturn'].includes(docSet.docType) && docSet.documents?.length !== 1) {
          errors.push(`Exactly one W-2 or one tax return document required for primary borrower`)
        }
      })
    } 

    if (includeCoBorrower === 'yes' && coDocsArray) {
      coDocsArray.forEach((docSet) => {
        if (docSet.docType === 'paystubs' && docSet.documents?.length !== 2) {
          errors.push(`Exactly two paystub documents required for co-borrower`)
        } else if (['W-2', 'taxreturn'].includes(docSet.docType) && docSet.documents?.length !== 1) {
          errors.push(`Exactly one W-2 or one tax return document required for co-borrower`)
        } 
      })
    } 
  }
  return errors
}

export const getFundReleaseDetails = (type: string, installer: string) => {
  switch (type?.toLowerCase()) {
    case 'roof funding release':
      return {
        title: 'Roof Funding Release',
        description: `Your installer, ${installer}, has issued a Roof Funding Release Request as part of your solar installation project. This step is required to permit the bank to release these funds (representing 100% of the roof project cost) on your behalf to your installer.`,
        requirements: [
          {
            title: '100% Completion of Roof Work:',
            detail: 'The roof must be fully repaired or replaced as per the project specifications.'
          },
          {
            title: 'Passed Inspection:',
            detail: 'The roof must pass all required inspections by the Authority Having Jurisdiction (AHJ). If the AHJ does not conduct inspections, the installer must provide a Completion Certificate, signed by the contractor, confirming the roof work is complete and meets all relevant standards.'
          }
        ]
      }
    case 'initial funding release':
      return {
        title: 'Initial Funding Release',
        description: `Your installer, ${installer}, has issued an Initial Funding Release Request as part of your solar installation project. This step is required to permit the bank to release these funds (representing the initial 50% of the solar project cost) on your behalf to your installer.`,
        requirements: [
          {
            title: 'Solar Permit:',
            detail: 'The installer must obtain the required permit in compliance with state regulations. If a permit is not required in your area, confirm that you have an understanding of the project details and timeline.'
          }
        ]
      }
    case 'final funding release':
      return {
        title: 'Final Funding Release',
        description: `Your installer, ${installer}, has issued a Final Funding Release Request as part of your solar installation project. This step is required to permit the bank to release these funds (representing the final 50% of the solar project cost) on your behalf to your installer.`,
        requirements: [
          {
            title: 'Solar Panel Installation:',
            detail: 'All solar panels are installed as expected.'
          },
          {
            title: 'Passed Inspection:',
            detail: 'The project must have passed all relevant inspections by the Authority Having Jurisdiction (AHJ).'
          },
          {
            title: 'Completion of Solar-Related Work:',
            detail: 'All solar-related work, including electrical connections and system set-up, must be completed as per the project specifications.'
          }
        ]
      }
    case 'full funding release':
      return {
        title: 'Full Funding Release',
        description: `Your installer, ${installer}, has issued a Full Funding Release Request as part of your solar installation project. This step is required to permit the bank to release these funds (representing 100% of the total project cost) on your behalf to your installer.`,
        requirements: [
          {
            title: 'Completed Permitting:',
            detail: 'All relevant permits have been obtained in compliance with all applicable state and local regulations.'
          },
          {
            title: 'Solar Panel Installation:',
            detail: 'All solar panels are installed as expected.'
          },
          {
            title: 'Passed Inspection:',
            detail: 'The project must have passed all relevant inspections by the Authority Having Jurisdiction (AHJ).'
          },
          {
            title: 'Completion of Solar-Related Work:',
            detail: 'All solar-related work, including electrical connections and system set-up, must be completed as per the project specifications.'
          },
          {
            title: 'Completion of Non-Solar Work:',
            detail: 'All non-solar work agreed upon in the solar contract must also be completed.'
          }
        ]
      }
    default: null
  }
}


export type Dictionary<T> = Record<string, T>

const UPLOAD_DOC_TYPES = {
  full_roof: 'Full Roof Funding Docs',
  solar_contract: 'Contract Docs',
  initial_funding_request: 'Initial Funding Docs',
  final_funding_request: 'Final Funding Docs',
  full_funding_request: 'Full Funding Docs',
  full_funding_docs_w_roof: 'Full Funding with Roof Docs',
  other: 'Other Docs',
  change_order: 'Change Order Docs',
}

export type UploadDocType = keyof typeof UPLOAD_DOC_TYPES

export const docTypeDescription = (type: UploadDocType): string => {
  return UPLOAD_DOC_TYPES[type] || '[unknown doc type]'
}

export type RequiredDocConfig = {
  _id?: string // only used during UI configuration
  slot: string
  instructions: string
}

export type InstallerStatus = {
  _id?: string
  status: string
  description?: string
  owner?: string
  sequence?: number
  uploadType?: UploadDocType
  deprecated?: boolean
  requiredDocuments?: RequiredDocConfig[]
  aliases?: string[] // other statuses that should be treated as equivalent
  progress?: number
  bg?: string
  fundReleaseType?: string
}

export type Incentive = {
  id: string
  amountFinanced: string
  emailHTML: string
  estPriceWithIncentives: string
  estPriceWithoutIncentives: string
  financingPartner: string
  financingPartnerLogo: string  // url
  incentiveDescription: string
  incentivizedRate: string
  landingPageURL: string
  loanDetailHTML: string
  loanTableHTML: string
  loanFees: string
  nonIncentiveRate: string
  prequalCopy: string
  programSavings: string
  savingsHTML: string
  tenant: string
  termsUrl: string
}

export type AgreementSchema = {
  browser: Record<string, unknown>
  timestamp: Date
  agrees: boolean
}

export type FundReleaseRequest = {
  _id: string
  type: string
  amount: number
  agreementSigned: AgreementSchema
  isEmailSent: boolean
  requestedAt: Date
  approvedFromIp: string
}

export type Adder = {
  description: string
  amount: number
}

export const getUploadSlots = (iscList: InstallerStatus[]): Dictionary<Dictionary<string>> => {
  const uploadSlots: Dictionary<Dictionary<string>> = {}
  iscList.forEach(statusConfig => {
    if (!statusConfig.uploadType) return // skip steps without uploadType
    uploadSlots[statusConfig.uploadType] = uploadSlots[statusConfig.uploadType] || {}
    statusConfig.requiredDocuments.forEach(reqDoc => {
      uploadSlots[statusConfig.uploadType][reqDoc.slot] = reqDoc.instructions
    })
  })

  return uploadSlots
}

export type IncomeDocument = {
  docType: string
  documents: File[]
}
import { useState } from "react"
import { FaChevronDown, FaChevronRight, FaExternalLinkAlt } from "react-icons/fa"

export const EquifaxDitReport = ({ item }) => {
  const [expand, setExpand] = useState(false)

  const report = item.report

  return <div className="list-group-item credit-report">
    <h5>DIT</h5>
    <div>CorrelationID: {report.correlationId}</div>
    <div>Decision: <b>{report.decision}</b></div>
    <div>Reference: {report.referenceTransactionId}</div>

    {item.ofacAlerts?.length ? <div className="alert alert-danger my-3">
      <h6 className="mb-0">OFAC Alerts</h6>
      {item.ofacAlerts?.map((alert, i) => <div key={i}>{alert.key}: {alert.value}</div>)}
    </div> : null}

    {item.secureDocUrl ? <div className="my-3">
      <a href={item.secureDocUrl} className="link" target="_blank">View Report <FaExternalLinkAlt /></a>
    </div> : null}
    
    <div className="section-hdr" onClick={() => setExpand(!expand)}>
      Details {expand ? <FaChevronDown /> : <FaChevronRight />}
    </div>

    {expand ? <table className="ppt-test">
      <thead>
        <tr>
          <th>Detail</th>
          <th>Value</th>
        </tr>
      </thead>
      <tbody>
        {report.details?.map((d, i) => <tr key={i}>
          <td>{d.key}</td>
          <td>{d.value}</td>
        </tr>)}
      </tbody>
    </table> : null}
  </div>
}

export default EquifaxDitReport
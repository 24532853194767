// Fund Realease 
export const FUND_RELEASE_SUPPORT_LINK = "https://oneethos.com/support/fund-release"
export const FUND_RELEASE_LOAN_HELP_LINK = "https://www.climatefirstbank.com/solar-loan-funding-help"
export const TEST_APP_ID = 9_999_999

export const FUND_RELEASE_TYPES = [
  "Roof Funding Release",
  "Initial Funding Release",
  "Final Funding Release",
  "Full Funding Release"
]

import React from 'react'

type BSVariant = 'primary' | 'success' | 'info' | 'warn' | 'danger' | 'secondary' | 'light'

type BadgeProps = {
  variant: BSVariant
  pill?: boolean
  outline?: boolean
  children
}

export const Badge = ({
  variant = "primary",
  outline = false,
  pill = true,
  children
}: BadgeProps) => (
  <span className={[
    "badge",
    `text-${outline ? '' : 'bg-'}${variant}`,
    pill ? "rounded-pill" : '',
    outline ? `border border-${variant}` : ''
  ].join(' ')}>{children}</span>
)


import { useState } from "react"
import { Spinner } from "@fluentui/react"
import { errorToString } from "@oneethos/shared"
import { useApi } from "../../hooks"
import api from "../../api-client"
import { toast } from "react-toastify"
import { SubformProps } from "."
import { getBrowserFingerprintData } from "../../browser-fingerprint"
import { lappToFormData } from "./util"

export const PrequalLetter = ({ formData, update }: Partial<SubformProps>) => {
  const [saving, setSaving] = useState(false)
  const [agreement, setAgreement] = useState(formData.preapprovalLetter?.agreement)

  const { fetching, error, data } = useApi(`/c/loanapps/${formData._id}/prequalification-letter`)

  if (fetching) {
    return <Spinner />
  }

  const expired = error && typeof error !== 'string' && error.code === 'PREQUAL_EXPIRED'

  if (error && typeof error === 'string') {
    return <div className="alert alert-danger">{error}</div>
  } else if (error && !expired) {
    return <div className="alert alert-danger">
      {errorToString(error)}
    </div>
  }

  const acked = formData.preapprovalLetter?.agreement?.agrees === true

  return <div className="prequal-letter">
    {expired ? <div className="alert alert-info">
      Your prequalification has expired; please contact the bank to discuss options.
    </div> : <>
      <>
        <p className="text-center">
          The letter below contains the details of your prequalification. Please review it
          carefully and save it for your records. Your prequalification is valid for 30 days.
        </p>
        <iframe
          src={data.url}
          title={`Prequalification Letter for ${formData._id}`}
          style={{
            width: '100%',
            height: '500px',
            border: 'none',
            marginTop: '20px'
          }}
        />

        {saving ? <Spinner style={{ padding: '2em' }} /> : (
          acked ? (
            <div className="alert alert-info mt-3">
              Thank you for acknowledging receipt of the prequalification letter. You should have
              received a copy via email, or you may download the copy above for your records.
            </div>
          ) : <div className={'form-group'} style={{ cursor: 'pointer', textAlign: 'left' }}>
            <label style={{ margin: '1em 0' }}>
              <input type="checkbox"
                onChange={ev => {
                  if (ev.target.checked) {
                    setAgreement({
                      browser: getBrowserFingerprintData(),
                      agrees: ev.target.checked
                    })
                  } else {
                    setAgreement(null)
                  }
                }}
                checked={agreement?.agrees === true}
              />
              &nbsp;
              I acknowledge that I have reviewed the above prequalification letter; by clicking
              "Accept", it will record my acknowledgement and email me a copy of this agreement.
            </label>
            <button type="button"
              className="btn btn-primary"
              disabled={agreement?.agrees !== true}
              onClick={() => {
                setSaving(true)
                api.patch(`/c/loanapps/${formData._id}/prequalification-letter`, {
                  contentMD5: data.contentMD5,
                  agreement: {
                    ...agreement,
                    timestamp: new Date(),
                  }
                }).then(res => update(lappToFormData(res)))
                  .catch(ex => toast.error(errorToString(ex)))
                  .finally(() => { setSaving(false) })
              }}>Accept</button>
          </div>
        )}
      </> 
    </>}
  </div>
}


import { Spinner } from "@fluentui/react"
import { errorToString, StatusData } from "@oneethos/shared"
import { useState } from "react"
import { BiMailSend } from "react-icons/bi"
import { toast } from "react-toastify"
import api from '../api-client'

type SendEnvelopeProps = {
  project: StatusData
  label: string
  template: 'loan-docs' | 'preapproval'
}

// TODO: refactor to use ActionLink

export const SendEnvelopeLink = ({ project, label, template }: SendEnvelopeProps) => {
  const [loading, setLoading] = useState(false)
  const [sent, setSent] = useState(false)

  if (loading) {
    return <Spinner />
  }

  return <div className="send-loan-docs">
    {sent ? <div className="badge text-bg-secondary">Envelope sent!</div> : <>
      <button
        className="btn btn-sm btn-link link-secondary p-0"
        onClick={() => {
          setLoading(true)
          api.post(`/loanapps/${project.id}/send-envelope`, { template })
            .then(() => {
              setSent(true)
            })
            .catch(ex => toast.error(errorToString(ex)))
            .finally(() => {
              setLoading(false)
            })
        }}
        disabled={loading}
      >
        <BiMailSend />
        &nbsp;{label}
      </button>
    </>}
  </div>
}


import { Spinner } from "@fluentui/react"
import { Validate } from "@oneethos/shared"
import { useState } from "react"
import { FormInput, SubformProps, Validation } from "."
import { AddressInputs } from "../../components/forms"

const { validateFields } = Validate


type CoborrowerPrequalProps = Partial<SubformProps> & {
  submitCombinedPrequal: (testPrequalResultCombined?: 'pass' | 'fail' | 'no-hit') => void
}

/**
 * This component overlaps heavily with the primary Eligibility component, but there are 
 * enough differences that it's likely best to keep them separate. Regardless, changes
 * to Eligibility component should likely be considered if making chanegs here.
 */
export const CoborrowerPrequal = ({
  formData,
  update,
  schema,
  saving,
  submitCombinedPrequal,
}: CoborrowerPrequalProps) => {
  const [validation, setValidation] = useState<Record<string, string>>({})

  // TODO: how to handle detection of combined perqual vs individual prequal?
  // const [prequalComplete, setPrequalComplete] = useState(!!formData.prequalResult)

  const validate = () => {
    return validateFields(schema, formData, [
      'coFirstName',
      'coLastName',
      'coEmail',
      'coBorrowerAddress',
      'coStatedGrossAnnualIncome'
    ])
  }

  return (
    <div>
      <h2>Add a Co-Borrower</h2>
      <p>
        Complete the following form to check combined
        eligibility for yourself plus a co-borrower.
      </p>
      <div className="row">
        <div className="col-md-4">
          <FormInput
            field='coFirstName'
            value={formData.coFirstName}
            onChange={s => update('coFirstName', s)}
          />
        </div>
        <div className="col-md-2">
          <FormInput
            field='coMiddleName'
            emptyOk
            value={formData.coMiddleName}
            onChange={s => update('coMiddleName', s)}
          />
        </div>
        <div className="col-md-6">
          <FormInput
            field='coLastName'
            value={formData.coLastName}
            onChange={s => update('coLastName', s)}
          />
        </div>
      </div>
      <FormInput
        field='coEmail'
        value={formData.coEmail || ''}
        onChange={s => update('coEmail', s)}
      />
      <FormInput
        field='coStatedGrossAnnualIncome'
        value={formData.coStatedGrossAnnualIncome}
        onChange={s => update('coStatedGrossAnnualIncome', s)}
      />
      <AddressInputs
        address={formData.coBorrowerAddress || { country: 'United States' }}
        label="Co-Borrower Address"
        onChange={address => update('coBorrowerAddress', address)}
      />

      <button
        type="button"
        className="btn btn-primary"
        disabled={saving}
        onClick={() => {
          const v = validate()
          setValidation(v)
          if (Object.keys(v).length === 0) {
            submitCombinedPrequal()
          }
        }}
      >{saving ? <Spinner /> : 'Check Combined Eligibility'}</button>
      {process.env.REACT_APP_NODE_ENV === 'test' ? <>
        {['pass', 'fail', 'no-hit'].map((test) => <button type="button" key={test}
          className="btn btn-secondary"
          onClick={() => {
            const v = validate()
            setValidation(v)
            if (Object.keys(v).length === 0) {
              submitCombinedPrequal(test as 'pass' | 'fail' | 'no-hit')
            }
          }}
        >Check Eligibility ({test})</button>)}
      </> : null}
      {validation ? (
        <Validation validation={validation} /> || <div className="alert alert-info">Form is valid</div>
      ) : null}

    </div>
  )
}
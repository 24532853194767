import React, { useState } from 'react'
import { SubformProps, Validation } from "."
import { Validate } from '@oneethos/shared'
import ApplicantForm from './applicant-form'
import { Spinner } from '@fluentui/react'

const { validateFields, ValidatorMap } = Validate

export const ContactInformation = ({ schema, formData, update, save, saving }: SubformProps) => {
  const [validation, setValidation] = useState<Record<string, string>>({})

  const validate = () => {
    const results = validateFields(schema, formData, [
      'firstName',
      'lastName',
      'ssn',
      'phone',
      'email',
      'birthDate',
      'driversLicenseNo',
      'driversLicenseIssueDate',
      'driversLicenseIssuingState',
      'driversLicenseExpirationDate',
      'userAddress',
      'citizenship'
    ])

    if (formData.installedAtDifferentLocation === 'yes') {
      const err = ValidatorMap.address(formData.installationAddress)
      if (err) {
        results['installationAddress'] = 'Installation address must be complete'
      }
    }

    if (!['yes', 'no'].includes(formData.hasMortgage)) {
      results['hasMortgage'] = 'Please indicate whether or not you have a mortgage'
    }

    if (!['yes', 'no'].includes(formData.propertyHeldInTrust)) {
      results['propertyHeldInTrust'] = 'Please indicate whether or not the property is held in a trust'
    }

    return results
  }

  return <>
    <h2>Applicant Information</h2>
    <ApplicantForm
      applicant={formData}
      update={update}
      address={formData.userAddress}
      onAddressChange={addr => update('userAddress', addr)}
      askForHeldInTrust
      disableEmail
    />
    <div>
      <button
        type="button"
        className="btn btn-primary"
        disabled={saving}
        onClick={() => {
          const v = validate()
          setValidation(v)
          if (Object.keys(v).length === 0) {
            save()
          }
        }}
      >{saving ? <Spinner /> : 'Continue'}</button>
    </div>
    {validation ? (
      <Validation validation={validation} /> || <div className="alert alert-info">Form is valid</div>
    ) : null}
  </>
}

export default ContactInformation
import React, { useState } from 'react'
import { InstallerStatusEditor, Section } from '../components'


export const InstallerStatusConfigPage = () => {
  return <>
    <Section>
      <InstallerStatusEditor />
    </Section>
  </>
}

export default InstallerStatusConfigPage

import { EquifaxDitConfig, TenantEquifaxConfig } from "@oneethos/shared"
import { PasswordInput, TextInput } from "../forms"

type EquifaxDITFormProps = {
  dit: EquifaxDitConfig
  setEquifaxConfig: (c: Partial<TenantEquifaxConfig>) => void
}

export const EquifaxDITForm = ({ dit, setEquifaxConfig }: EquifaxDITFormProps) => {
  return <div>
    <h4>Equifax Digital Identity Trust (DIT)</h4>
    <div className="form-group">
      <label>Equifax DIT Base URL</label>
      <TextInput
        value={dit?.baseUrl || ''}
        onChange={val => setEquifaxConfig({ dit: { ...dit, baseUrl: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax DIT Client ID</label>
      <TextInput
        value={dit?.clientId || ''}
        onChange={val => setEquifaxConfig({ dit: { ...dit, clientId: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax DIT Client Secret</label>
      <PasswordInput
        value={dit?.clientSecret}
        onChange={val => setEquifaxConfig({ dit: { ...dit, clientSecret: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax DIT Application ID</label>
      <TextInput
        value={dit?.applicationId || ''}
        onChange={val => setEquifaxConfig({ dit: { ...dit, applicationId: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax DIT Tenant ID</label>
      <TextInput
        value={dit?.tenantId || ''}
        onChange={val => setEquifaxConfig({ dit: { ...dit, tenantId: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax DIT Config ID</label>
      <TextInput
        value={dit?.configId || 'configId'}
        disabled
        onChange={val => setEquifaxConfig({ dit: { ...dit, configId: val } })}
      />
    </div>

    <div className="form-group">
      <label>Equifax DIT Entity ID</label>
      <TextInput
        value={dit?.entityId || 'entityId'}
        disabled
        onChange={val => setEquifaxConfig({ dit: { ...dit, entityId: val } })}
      />
    </div>
  </div>
}
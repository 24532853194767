import React, { useContext, useEffect, useState } from 'react'
import { errorToString, InstallerStatus, RequiredDocConfig, FUND_RELEASE_TYPES } from '@oneethos/shared'
import api from '../api-client'
import { toast } from 'react-toastify'
import { AppContext } from '../appContext'
import actions from '../actions'
import './installer-status-editor.scss'
import { MultiSelect, NumericInput, SingleSelect, TextInput } from './forms'
import { FaTrash } from 'react-icons/fa'
import { Spinner } from '@fluentui/react'
import { v4 } from 'uuid'
import { ArrayEditor } from './forms/array-editor'

type ISCItemEditorProps = {
  docTypes: RequiredDocConfig[]
  statusConfig: InstallerStatus
  onChange: (sc: InstallerStatus) => void
}

const ISCItemEditor = ({ statusConfig, onChange, docTypes }: ISCItemEditorProps) => {
  return <div className="isc-item-editor">
    <div className="form-group">
      <label>Step Name</label>
      <TextInput
        value={statusConfig.status}
        onChange={status => onChange({ ...statusConfig, status })}
      />
    </div>
    <div className="form-group">
      <label>Required Action</label>
      <TextInput
        value={statusConfig.description}
        onChange={description => onChange({ ...statusConfig, description })}
      />
    </div>
    <div className="form-group">
      <label>Upload Type</label>
      <TextInput
        value={statusConfig.uploadType}
        onChange={uploadType => onChange({ ...statusConfig, uploadType })}
      />
    </div>
    <div className="form-group">
      <label>Sequence</label>
      <TextInput
        value={statusConfig.sequence?.toString()}
        emptyOk
        onChange={sequence => onChange({ ...statusConfig, sequence })}
      />
    </div>
    <div className="form-group">
      <label>Owner</label>
      <select className="form-select"
        value={statusConfig.owner}
        onChange={ev => onChange({ ...statusConfig, owner: ev.target.value })}
      >
        <option></option>
        <option value="Consumer">Consumer</option>
        <option value="Bank">Bank</option>
        <option value="Installer">Installer</option>
      </select>
    </div>
    <div className="form-group">
      <label>Deprecated</label>
      <select className="form-select"
        value={statusConfig.deprecated?.toString() || 'false'}
        onChange={ev => onChange({ ...statusConfig, deprecated: ev.target.value === 'true' })}
      >
        <option value="false">false</option>
        <option value="true">true</option>
      </select>
    </div>
    <div className="form-group">
      <label>Progress</label>
      <NumericInput
        value={statusConfig.progress?.toString() || ''}
        onChange={val => onChange({ ...statusConfig, progress: parseInt(val) })}
      />
    </div>
    <div className="form-group">
      <label>Alternate Statuses / Aliases </label>
      <ArrayEditor
        prompt='Add new status alias'
        values={statusConfig.aliases || []}
        itemType='aliases'
        onChange={aliases => onChange({ ...statusConfig, aliases })}
      />
    </div>
    <div className="form-group">
      <label>Required Documents</label>
      <MultiSelect
        options={docTypes.reduce((acc, { slot }) => {
          acc[slot] = slot
          return acc
        }, {})}
        values={statusConfig.requiredDocuments?.map(rd => rd.slot)}
        onChange={slots => {
          const requiredDocuments = slots.map(slot => {
            const instructions = docTypes.find(dt => dt.slot === slot)?.instructions || ''
            return { slot, instructions }
          }) || []
          onChange({ ...statusConfig, requiredDocuments })
        }}
      />
    </div>
    <div className="form-group">
      <label>Fund Release Types</label>
      <SingleSelect
        options={FUND_RELEASE_TYPES}
        value={statusConfig.fundReleaseType}
        onChange={fundReleaseType => onChange({ ...statusConfig, fundReleaseType })}
      />
    </div>
  </div>
}

type DocTypesEditorProps = {
  docTypes: RequiredDocConfig[]
  onChange: (docTypes: RequiredDocConfig[], idChanged: string) => void
  onDelete: (id: string) => void
}

const DocTypesEditor = ({ docTypes, onChange, onDelete }: DocTypesEditorProps) => {
  return <div className="doc-types">
    {docTypes.map(({ slot, instructions, _id }, idx) => {
      return <div key={idx} className="doc-type">
        <div className="form-group">
          <label>Document Type</label>
          <TextInput
            value={slot}
            emptyOk
            onChange={slot => {
              const nextDocTypes = [...docTypes]
              nextDocTypes.splice(idx, 1, { slot, instructions, _id })
              onChange(nextDocTypes, _id)
            }}
          />
          <div className="actions">
            <FaTrash size={20} style={{ cursor: 'pointer' }}
              onClick={() => {
                if (!window.confirm(
                  'Delete this document type? This will remove it from any status configurations that require this document.'
                )) return

                onDelete(_id)
              }}
            />
          </div>
        </div>
        <div className="form-group">
          <label>Instructions</label>
          <textarea
            className="form-control"
            value={instructions}
            rows={5}
            onChange={ev => {
              const nextDocTypes = [...docTypes]
              nextDocTypes.splice(idx, 1, { slot, instructions: ev.target.value, _id })
              onChange(nextDocTypes, _id)
            }}
          />
        </div>
      </div>
    })}
    <div className="buttons mb-3">
      <button className="btn btn-secondary"
        onClick={() => {
          const nextDocTypes = [...docTypes]
          const docType = {
            slot: '',
            instructions: '',
            _id: v4()
          }
          nextDocTypes.push(docType)

          onChange(nextDocTypes, docType._id)
        }}
      >Add Document Type</button>
    </div>
  </div>
}

export const InstallerStatusEditor = () => {
  const { dispatch, state: { installersStatusConfig } } = useContext(AppContext)
  const [list, setList] = useState([])
  const [docTypes, setDocTypes] = useState([])
  const [nav, setNav] = useState('docTypes')
  const [saving, setSaving] = useState(false)
  const [activeIdx, setActiveIdx] = useState(null)

  useEffect(() => {
    // strip out _ids and the "unknown" status
    const _list = installersStatusConfig.list?.filter(c => c.status !== "unknown").map(item => {
      const sc = { ...item }
      delete sc._id
      sc.requiredDocuments?.forEach(rd => delete rd._id)
      return sc
    }) || []

    setList(_list)

    const _docTypes = {}
    _list?.forEach(statusConfig => {
      // create a unique list of docTypes to enforce some conformity and make it easier 
      // to propagate change in the UI between docTypes and statusConfigs as changes are made
      statusConfig.requiredDocuments?.forEach(reqDoc => {
        if (!_docTypes[reqDoc.slot]) {
          reqDoc._id = v4()
        } else {
          reqDoc._id = _docTypes[reqDoc.slot]._id
        }

        _docTypes[reqDoc.slot] = reqDoc
      })
    })

    setDocTypes(Object.values(_docTypes))
  }, [installersStatusConfig.list])

  if (installersStatusConfig.loading) {
    return <div>Loading...</div>
  }

  return (
    <div className="isc-editor">
      <h3>Installer Status Config</h3>
      <div className="alert alert-info">
        Changes to Installer Status Configurations not applied until you
        click "Save Installer Status Config" button at the bottom of this section
      </div>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <span className={`nav-link ${nav === 'docTypes' ? 'active' : ''}`}
            onClick={() => setNav('docTypes')}>Document Types</span>
        </li>
        <li className="nav-item">
          <span className={`nav-link ${nav === 'statuses' ? 'active' : ''}`}
            onClick={() => setNav('statuses')}>Statuses</span>
        </li>
      </ul>
      {nav === 'docTypes' ? <DocTypesEditor
        docTypes={docTypes}
        onDelete={_id => {
          const nextDocTypes = docTypes.filter(dt => dt._id !== _id)
          setDocTypes(nextDocTypes)
          const nextList = JSON.parse(JSON.stringify(list))
          nextList.forEach(sc => {
            sc.requiredDocuments = sc.requiredDocuments?.filter(rd => rd._id !== _id) || []
          })
          setList(nextList)
        }}
        onChange={(nextDocTypes, _id) => {
          setDocTypes(nextDocTypes)
          const updatedDocType = nextDocTypes.find(dt => dt._id === _id)
          const nextList = JSON.parse(JSON.stringify(list))
          nextList.forEach(sc => {
            sc.requiredDocuments = sc.requiredDocuments?.map(rd => {
              return rd?._id === _id ? updatedDocType : rd
            }) || []
          })
          setList(nextList)
        }}
      /> : null}
      {nav === 'statuses' ? <div className="form-group" style={{ display: 'flex', alignItems: 'flex-start' }}>
        <table className="table isc-statuses table-hover">
          <thead>
            <tr>
              <th>Status</th>
              <th>Owner</th>
              <th>Upload Type</th>
              <th>Seq</th>
              <th>Progress</th>
            </tr>
          </thead>
          <tbody>
            {list.map((sc, idx) => {
              return <tr key={idx}
                className={[
                  activeIdx === idx ? 'active' : '',
                  sc.deprecated ? 'deprecated' : ''
                ].join(' ')}
                onClick={() => setActiveIdx(idx)}>
                <td>
                  <div className="isc-name">{sc.status}</div>
                  <div className="dep-text">
                    {sc.deprecated ? 'deprecated' : null}
                  </div>
                </td>
                <td>{sc.owner}</td>
                <td>{sc.uploadType}</td>
                <td>{sc.sequence}</td>
                <td>{sc.progress}</td>
              </tr>
            })}
          </tbody>
        </table>
        {activeIdx !== null ? <div className="ms-3 isc-item-panel rounded">
          <div className="d-flex justify-content-end">
            <button
              className="btn btn-close"
              onClick={() => setActiveIdx(null)}
              aria-label="Close"
            />
          </div>

          <ISCItemEditor
            docTypes={docTypes}
            statusConfig={list[activeIdx]}
            onChange={statusConfig => {
              const nextList = [...list]
              nextList.splice(activeIdx, 1, statusConfig)
              setList(nextList)
            }}
          />
        </div> : null
        }
      </div > : null}
      <div className="buttons">
        <button
          type="button"
          className="btn btn-primary"
          disabled={saving}
          onClick={() => {
            setSaving(true)
            const statuses = JSON.parse(JSON.stringify(list))
            statuses.forEach(sc => {
              sc.requiredDocuments = sc.requiredDocuments?.map(rd => {
                delete rd._id
                return rd
              }) || []
            })

            api.put('/installers-status', { statuses }).then(res => {
              toast.success('Installer Status Config saved')
              api.get('/installers-status').then(res => {
                dispatch(actions.setInstallersStatusConfig(res))
              }).catch(ex => {
                dispatch(actions.setInstallersStatusConfigError(ex.error))
              }).finally(() => setSaving(false))
            }).catch(ex => toast.error(errorToString(ex)))
              .finally(() => setSaving(false))
          }}
        >{saving ? <Spinner /> : 'Save Installer Status Config'}</button>

        <button className="btn btn-secondary"
          onClick={() => {
            const nextList = [...list]
            nextList.push({ status: '(new)', requiredDocuments: [] })
            setList(nextList)
            setActiveIdx(nextList.length - 1)
          }}
        >Add Status</button>
      </div>
    </div >
  )
}

export default InstallerStatusEditor

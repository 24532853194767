import { ApplicantRaceValues, Citizenship } from "@oneethos/shared"
import { IStreetAddress, LoanApplication } from "@oneethos/shared"
import { FormInput } from "."
import { StateOptions, AddressInputs } from "../../components/forms"

type ApplicantFormProps = {
  applicant: Partial<LoanApplication>
  update: (data: string | Record<string, unknown>, value?: unknown) => void
  onAddressChange: (address: IStreetAddress) => void
  address: IStreetAddress
  disableEmail?: boolean
  askForHeldInTrust?: boolean
}

export const ApplicantForm = ({
  applicant,
  update,
  onAddressChange,
  address,
  disableEmail,
  askForHeldInTrust
}: ApplicantFormProps) => {
  return (
    <>
      <FormInput
        field='firstName'
        value={applicant.firstName}
        onChange={s => update('firstName', s)}
      />
      <FormInput
        field='lastName'
        value={applicant.lastName}
        onChange={s => update('lastName', s)}
      />
      <FormInput
        field='ssn'
        value={applicant.ssn || ''}
        onChange={s => update('ssn', s)}
      />
      <FormInput
        field='phone'
        value={applicant.phone || ''}
        onChange={s => update('phone', s)}
      />
      <FormInput
        field='email'
        value={applicant.email || ''}
        onChange={s => update('email', s)}
        disabled={disableEmail}
      />
      <FormInput
        field='birthDate'
        value={applicant.birthDate || ''}
        onChange={s => update('birthDate', s)}
      />
      <FormInput
        field='driversLicenseNo'
        value={applicant.driversLicenseNo || ''}
        onChange={s => update('driversLicenseNo', s)}
      />
      <div className="form-group">
        <label>Drivers License Issuing State</label>
        <select
          className="form-select"
          value={applicant.driversLicenseIssuingState}
          onChange={ev => update('driversLicenseIssuingState', ev.target.value)}
        >
          <option>Choose state...</option>
          <StateOptions />
        </select>
      </div>
      <FormInput
        field='driversLicenseIssueDate'
        value={applicant.driversLicenseIssueDate}
        onChange={s => update('driversLicenseIssueDate', s)}
      />
      <FormInput
        field='driversLicenseExpirationDate'
        value={applicant.driversLicenseExpirationDate}
        onChange={s => update('driversLicenseExpirationDate', s)}
      />
      <div className="form-group">
        <label>Citizenship</label>
        <select
          className="form-select"
          value={applicant.citizenship || ''}
          onChange={ev => update('citizenship', ev.target.value)}
        >
          <option>Choose Citizenship...</option>
          {Citizenship.options().map(([i, label]) =>
            <option key={i} value={i}>{label}</option>
          )}
        </select>
      </div>
      <div className="form-group">
        <label>Race</label>
        <select
          className="form-select"
          value={applicant.race || ''}
          onChange={ev => update('race', ev.target.value)}
        >
          <option>Choose Race...</option>
          {ApplicantRaceValues.map(r =>
            <option key={r} value={r}>{r}</option>
          )}
        </select>
      </div>
      <FormInput
        field='statedHouseholdIncome'
        value={applicant.statedHouseholdIncome || ''}
        onChange={s => update('statedHouseholdIncome', s)}
      />
      <div className="form-group">
        <label>Do you currently have a mortgage in your name at any property?</label>
        <select
          className="form-select"
          value={applicant.hasMortgage || ''}
          onChange={ev => update('hasMortgage', ev.target.value)}
        >
          <option></option>
          <option value='yes'>yes</option>
          <option value='no'>no</option>
        </select>
      </div>

      {askForHeldInTrust && (
        <div className="form-group">
          <label>Is the property where the panels will be located held in a trust?</label>
          <select
            className="form-select"
            value={applicant.propertyHeldInTrust || ''}
            onChange={ev => update('propertyHeldInTrust', ev.target.value)}
          >
            <option></option>
            <option value='yes'>yes</option>
            <option value='no'>no</option>
          </select>
        </div>
      )}

      <AddressInputs
        address={address}
        label="Home Address"
        onChange={address => onAddressChange(address)}
      />
    </>
  )
}

export default ApplicantForm